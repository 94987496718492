
export const loginActions = {
  ForgotPassword: 'forgot-password',
  Login: 'login',
  Logout: 'logout',
  ResetPassword: 'reset-password',
};

export const settingActions = {
  Profile: 'profile',
  Notifications: 'notifications',
  Privacy: 'privacy',
};

export const companyManagementActions = {
  UserManagement: 'user-management',
  InviteUsers: 'invite-users',
  BillingInfo: 'billing-info',
  CompanyInfo: 'company-info',
  ReviewerTypeTemplates: 'reviewer-type-templates',
};

export const companyActions = {
  Dashboard: 'dashboard',
  CreateCompany: 'create-company',
  VerifiedComanyCreation: 'verified-company-completion',
  InviteUser: 'invite-user',
  AcceptInvite: 'accept-invite',
  ContactUs: 'contact-us',
  ConfirmCompany: 'confirm-user',
};

export const CASE_STEPS = {
  GENERAL: 'general',
  REVIEWER_TYPE: 'reviewer-type',
};

export const REVIEWER_TYPE_MENU_ITEMS = {
  REVIEWER_TYPE: 'reviewer-type',
  ADDITIONAL: 'additional',
  RELATIVITY: 'relativity',
  SETTINGS: 'settings',
  SERVICES: 'services',
} as const;

// export type ReviewerTypeMenuItem = typeof REVIEWER_TYPE_MENU_ITEMS[keyof typeof REVIEWER_TYPE_MENU_ITEMS];

export const REVIEWER_TYPE_YES_NO_QUESTION_SUFFIX = 'StepYesNo';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_MOMENT = 'MM/DD/yyyy';

export const MAX_TIME_ENTRY_ENTERING_DAYS = 14;

export const ANNOUNCEMENTS_DIALOG_STORAGE_KEY = 'showAnnouncementsDialog';
